.slick-track {
  display: flex !important;
  margin-left: 0 !important;
}
.slick-slide {
  height: inherit !important;
}
.slick-slide > div {
  height: 100%;
}
.slick-list {
  margin-left: -0px !important;
  margin-bottom: 2rem;
  padding-left: 0 !important;
}
.slick-dots {
  position: relative !important;
  bottom: 0 !important;
  display: flex !important;
  justify-content: center;
  gap: 0.5rem;
  padding: 0;
  margin: 0;
  list-style: none;
}
.slick-dots li {
  margin: 0;
  padding: 0;
  width: 8px;
  height: 8px;
}
.slick-dots li button {
  width: 8px;
  height: 8px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: #D1D5DB;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
}
.slick-dots li button:before {
  display: none;
}
.slick-dots li.slick-active button {
  background-color: #3B82F6;
}
