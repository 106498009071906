.scrollText {
    display: inline-block;
    white-space: nowrap;
  }
  
  .scrolling {
    animation: scroll linear infinite;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }