
.page {
  /* width: 352px;
  height: 182px;
  object-fit: contain; */
  background-color: #090f14 !important;
  height: -webkit-fill-available;
}
.page canvas {
  margin-left: auto;
  margin-right: auto;
  width: 100% !important;
  height: 100% !important;
}