
  .cardsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 24px;
    justify-items: center;
  }
  
  .cardBoxWrapper,
  .cardWrapper {
    width: 100%;
    max-width: 349px;
    cursor: pointer;
  }
  
  @media (max-width: 1079px) {
    .cardsGrid {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }
  
  @media (max-width: 767px) {
    .cardsGrid {
      grid-template-columns: 1fr;
    }
    
    .cardBoxWrapper,
    .cardWrapper {
      max-width: 100%;
    }
    
  }