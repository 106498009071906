.cardsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
  justify-items: center;
}

.cardBoxWrapper,
.cardWrapper {
  width: 100%;
  max-width: 349px;
  cursor: pointer;
}
.reviewContainer {
    margin-top: 65px;
  font-family: "Space Grotesk", sans-serif;
  color: white;
  text-align: left;
}
.guestTitle {
  font-size: 21px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
}
.guestTime {
  font-size: 10px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 15px;
}
.guestcontent {
  font-size: 14px;
  font-weight: lighter;
  margin: 0;
}
.guestCardContainer {
  padding: 20px 22px;
}
