.chatBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  flex: 1;
  text-align: left;
}
.chatBoxMain {
  display: flex;
  justify-content: center;
  align-items: start;
  flex: 1;
}
.heading {
  font-size: 30px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: bold;
  color: white;
  margin: 0;
}
.lineBreak {
  border: none; /* Removes the default border */
  border-top: 0.1px solid #d4d4d8; /* Adds a thin horizontal line */
  margin: 10px 0; /* Adjusts the spacing above and below the line */
  width: 100%; /* Ensures the line spans the container */
}
.helperText {
  font-size: 18px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: normal;
  color: #5d6673;
}
.stepsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Distance between rows */
  margin-bottom: 20px;
}

.stepRow {
  display: flex;
  align-items: center; /* Align box and text */
  gap: 10px; /* Distance between box and text */
}

.stepBox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #006fee;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px; /* Adjust font size for visibility */
  font-weight: 400; /* Optional for better readability */
  flex-shrink: 0;
}
.wave {
  width: 100%;
  min-height: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0d141c;
  border-radius: 20px;
  margin-top: 60px;
  padding: 18px;
}
.helperBox {
  width: 100%;
  /* height: 300px; */
  overflow: auto;
  background-color: #0d141c;
  border-radius: 20px;
  margin-top: 20px;
  padding: 18px;
}

.stepText {
  font-size: 16px;
  font-family: "Space Grotesk", sans-serif;
  color: white;
  margin: 0; /* Remove default margin for `p` tags */
  flex: 1;
}
.startButtonBox {
  margin-top: 20px; /* Default margin */
}

.startButtonAtBottom {
  position: absolute;
  bottom: 20px; /* Adjust spacing from the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
}

.chatText {
  font-size: 30px;
  font-weight: bold;
  color: #5d6673;
  font-family: "Space Grotesk", sans-serif;
  text-align: left;
  margin: 0;
  margin-bottom: 15px;
}
.chatQuestions {
  font-size: 20px;
  font-weight: bold;
  color: #5d6673;
  font-family: "Space Grotesk", sans-serif;
  text-align: left;
  margin: 0;
  margin-bottom: 15px;
}
.chatContent {
  /* height: 600px; */
  overflow: auto;
}
.chatContentBox {
  display: flex;
  flex-direction: column; /* Stack content and footer vertically */
  justify-content: flex-start; /* Align items at the top */
  height: 100%; /* Ensure the container takes full height */
}
.chatFooter {
  width: 100%; /* Full width of the parent container */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto; /* Push footer to the bottom */
}
.footerReviewBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
  width: 100%;
}
.footerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;
}
.listenBox {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
}
.circle {
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background-color: #39f2ae;
}
.listenText {
  margin: 0;
  font-size: 16px;
  font-family: "Space Grotesk", sans-serif;
  color: #39f2ae;
}
