.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px); /* Blurs the background */
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden !important;
}

.loader {
  position: relative;
  display: flex;
  font-size: 12px;
}
.loader:hover * {
  animation-play-state: paused;
}
.loader:active * {
  animation-play-state: running;
}
.loader::after {
  content: '';
  position: absolute;
  top: calc(50% - 0.15em);
  left: -5vw;
  height: 0.3em;
  width: calc(100% + 10vw);
  border-radius: 50%;
  background-color: currentColor;
  transform: translateZ(0);
  filter: blur(0.15em);
  opacity: 0.8;
  box-shadow: 0 0 1em #ccf, 0 0 2em 0.15em rgba(153, 153, 255, 1), 0 0 2em #ccf;
}
.o {
  position: relative;
  left: -42px;
  z-index: 1;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: currentColor;
  margin-left: 3em;
  margin-right: 3em;
  animation: translateX 1s linear infinite;
  filter: blur(0.3em);
  box-shadow: 0 0 0.15em, 0 0 1em 0.5em rgba(153, 153, 255, 1),
    0 0 3em 1.5em rgba(153, 153, 255, 0.2), 0 0 5em rgba(153, 153, 255, 0.5);
}
.o:first-child {
  animation-name: translateX, hide-show;
  animation-timing-function: linear, step-end;
}
.o:last-child {
  transform-origin: -99px 50%;
  animation-name: helf-rotate;
  animation-timing-function: cubic-bezier(0.25, 0, 0.4, 1.25);
}
@keyframes translateX {
  100% {
    transform: translateX(84px);
  }
}
@keyframes helf-rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    opacity: 1;
    transform: rotate(180deg);
  }
  50.1%,
  100% {
    opacity: 0;
  }
}
@keyframes hide-show {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.box {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  background: inherit;
  filter: contrast(3);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
  line-height: 1.4;
  color: #fff;
  /* background-color: #000; */
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  height: 100%;
}
.text {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}
