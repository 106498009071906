/* src/components/TiptapDocumentStyles.css */

/* Basic styling for the Tiptap editor */
.ProseMirror {
    padding: 0.5rem;
    border-radius: 0.375rem;
    min-height: 120px;
    width: 100%;
  }
  
  .ProseMirror:focus {
    outline: none;
  }
  
  /* Font size styling */
  .ProseMirror [style*="font-size: x-small"] {
    font-size: 0.75rem;
  }
  
  .ProseMirror [style*="font-size: small"] {
    font-size: 0.875rem;
  }
  
  .ProseMirror [style*="font-size: medium"] {
    font-size: 1rem;
  }
  
  .ProseMirror [style*="font-size: large"] {
    font-size: 1.125rem;
  }
  
  .ProseMirror [style*="font-size: x-large"] {
    font-size: 1.25rem;
  }
  
  /* Bubble menu styling */
  .tippy-box {
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  
  /* Typography for content inside the editor */
  .ProseMirror p {
    margin: 0.5em 0;
  }
  
  .ProseMirror h1 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0.75em 0 0.5em 0;
  }
  
  .ProseMirror h2 {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0.75em 0 0.5em 0;
  }
  
  .ProseMirror h3 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0.75em 0 0.5em 0;
  }
  
  /* Lists */
  .ProseMirror ul,
  .ProseMirror ol {
    padding-left: 1.5em;
    margin: 0.5em 0;
  }
  
  .ProseMirror li {
    margin-bottom: 0.25em;
  }
  
  /* Blockquote */
  .ProseMirror blockquote {
    border-left: 2px solid #ddd;
    padding-left: 0.75em;
    margin-left: 0;
    font-style: italic;
  }
  
  /* Code blocks */
  .ProseMirror pre {
    background: #f1f1f1;
    border-radius: 0.25rem;
    padding: 0.5em;
    margin: 0.5em 0;
    overflow-x: auto;
  }
  
  /* Inline code */
  .ProseMirror code {
    background: #f1f1f1;
    padding: 0.1em 0.3em;
    border-radius: 0.25rem;
    font-size: 0.9em;
  }
  
  /* Dark mode adjustments */
  .dark-theme .ProseMirror pre,
  .dark-theme .ProseMirror code {
    background: #2d3748;
  }
  
  .dark-theme .ProseMirror blockquote {
    border-left-color: #4a5568;
  }