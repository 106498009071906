.App {
  /* display: flex;
  flex-direction: column; */
  min-height: 85vh;
  text-align: center;

}
.container {
  width: 90%;
  margin: auto;
}
.container-95 {
  width: 95%;
  margin: auto;
}
.w-full{
  width: 100%;
}
.lineBreak {
  border: none; /* Removes the default border */
  border-top: 0.1px solid #5d6673; /* Adds a thin horizontal line */
  margin: 10px 0; /* Adjusts the spacing above and below the line */
  width: 100%; /* Ensures the line spans the container */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #C8DEF8 rgba(57, 242, 174, 0.1);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* React toastify styles */
.Toastify__toast {
  background: #ffffff;
  border-radius: 15px !important;
}

.Toastify__toast-container {
  width: 350px !important;
}

.Toastify__toast-theme--light {
  color: white !important;
  font-family: 'Work Sans', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

.Toastify__toast-body {
  width: 405px !important;
  flex: unset !important;
}

.Toastify__close-button--light {
  color: #fff !important;
  opacity: 1 !important;
  margin: 0;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translate(-50%, -50%);
}

.Toastify__toast--success {
  background: rgba(38, 175, 126, 1) !important ;
  color: white !important
}

.Toastify__toast--error {
  background: #d30d44 !important;
  color: white !important
}

.Toastify__toast--warning {
  background: rgba(221, 126, 72, 1) !important;
  color: white !important
}

.Toastify__toast--info {
  background: rgba(108, 81, 216, 1) !important;
  color: white !important;
}

.Toastify__toast--info .Toastify__close-button--light {
  color: #3f352d !important;
}
.Toastify__toast-icon {
  width: 30px !important;
}

/* .Toastify__toast-icon svg {
  fill: white !important;
}

.Toastify__toast--info svg,
.Toastify__toast--success svg {
  fill: #3f352d !important;
} */

/* Add to your CSS */
@keyframes pulse-subtle {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.95;
  }
}

.animate-pulse-subtle {
  animation: pulse-subtle 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}