.modalContent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
    z-index: 1000;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px); /* For Safari support */
  }
  
  /* Add new class for the main content container */
  .modalWrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  
  .header {
    margin-bottom: 16px;
  }
  
  .title {
    font-size: 20px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 8px;
  }
  
  .description {
    color: #4B5563;
    margin-bottom: 24px;
  }
  
  .highlight {
    font-weight: 500;
    color: #111827;
  }
  
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
  }
  
  .button {
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    border: none;
  }
  
  .cancelButton {
    composes: button;
    background: #F3F4F6;
    color: #111827;
  }
  
  .cancelButton:hover {
    background: #E5E7EB;
  }
  
  .confirmButton {
    composes: button;
    background: #39F2AE;
    color: white;
  }
  
  .confirmButton:hover {
    background: #34D99C;
  }