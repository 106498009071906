.main{
    display: flex;
    width: 100%;
    gap: 17px;
}

.chatBox{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 1;
}
.startButtonBox{
   
}